<template>
  <div class="iss-main contactFrequency">
    <div class="list-wrapper">
      <div class="reminderTitle">
        <div class="reminderT">
          <div class="titleT">待办</div>
          <div class="titleText">记住重要的日期和后续的行动。</div>
        </div>
        <div class="ReminderR">
          <div class="addReminder" @click="handleAddReminder">新增待办</div>
        </div>
      </div>
      <div class="tabsClass">
        <a-tabs v-model:activeKey="activeKey">
          <a-tab-pane key="1" tab="待办">
            <one-page />
          </a-tab-pane>

          <a-tab-pane key="2" tab="已完成" force-render>
            <two-page />
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
<!--  这里换成纯新增联系人和人脉圈待办   -->
    <add-menber-page
      v-model:visible="editPage"
      :init="activeItem"
      @fnOk="handleEditPage"
    />
  </div>
</template>

<script>
// Form, DatePicker, Avatar,
import { getCurrentInstance, reactive, toRefs, watch } from 'vue';
import { Tabs } from 'ant-design-vue';
import onePage from '@/views/backlog/onePage';
import twoPage from '@/views/backlog/twoPage';
import addMenberPage from '@/views/today/homePage/components/addMenberPage';

export default {
  components: {
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    onePage,
    twoPage,
    addMenberPage,
  },
  props: {
    type: String,
    search: Object,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      activeKey: '1',
      editPage: false,
      activeItem: {},
    });
    const form = reactive({});

    watch(
      () => state.activeKey,
      value => {
        if (value === '1') {
          proxy.$bus.emit('sxOnePage', true);
        } else {
          proxy.$bus.emit('sxTwoPage', true);
        }
      }
    );

    return {
      ...toRefs(state),
      form,
      handleAddReminder() {
        let parmas = {
          comeFrom: 'backlog',
          type: 'add',
        };
        state.editPage = true;
        Object.assign(state.activeItem, parmas);
      },
      handleEditPage(value) {
        state.editPage = value;
      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-main {
  padding-top: 40px;
  padding-left: 32px;
  padding-right: 32px;
}

.tabsClass {
  margin-top: 38px;
}

.tabsClass /deep/ .ant-tabs-bar {
  margin: 4px 0px 0px 0px;
  border-bottom: 0.5px solid #eeeeee;
}

.reminderTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .reminderT {
    width: 80%;
    .titleT {
      font-size: 24px;
      font-weight: 500;
    }
    .titleText {
      font-size: 12px;
      color: #666666;
      margin-top: 8px;
    }
  }
  .ReminderR {
    width: 20%;
    display: flex;
    justify-content: right;
  }

  .addReminder {
    padding: 8px 12px;
    background: #ff7b00;
    border-radius: 4px;
    font-size: 12px;
    font-weight: normal;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
  }
}
</style>
